import React, { useState, useRef } from 'react';
import { ArrowRight, Upload, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar.tsx';

const UploadPage: React.FC = () => {
  const [apiKey, setApiKey] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [responseMessage, setResponseMessage] = useState<{ type: 'success' | 'error' | 'loading' | null, message: string }>({ type: null, message: '' });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.classList.add('border-indigo-400', 'bg-gray-700');
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.classList.remove('border-indigo-400', 'bg-gray-700');
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.classList.remove('border-indigo-400', 'bg-gray-700');
    if (e.dataTransfer.files.length) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!apiKey.trim()) {
      setResponseMessage({ type: 'error', message: 'Please enter your API key.' });
      return;
    }

    if (!file) {
      setResponseMessage({ type: 'error', message: 'Please select a file to upload.' });
      return;
    }

    setResponseMessage({ type: 'loading', message: 'Uploading... Please wait.' });

    try {
      // Step 1: Request a pre-signed URL
      const presignedUrlResponse = await fetch('https://58zs63hqkd.execute-api.us-east-2.amazonaws.com/test/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        },
        body: JSON.stringify({
          fileName: file.name,
          fileType: file.type
        })
      });

      if (!presignedUrlResponse.ok) {
        const errorData = await presignedUrlResponse.json();
        throw new Error(errorData.message || 'Failed to get pre-signed URL');
      }

      const { url, fileName } = await presignedUrlResponse.json();

      // Step 2: Upload the file using the pre-signed URL
      const uploadResponse = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type
        },
        body: file
      });

      if (!uploadResponse.ok) {
        throw new Error('Upload failed');
      }

      setResponseMessage({ type: 'success', message: `File uploaded successfully. File: ${fileName}` });
    } catch (error) {
      setResponseMessage({ type: 'error', message: error.message || 'An error occurred during upload.' });
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 flex flex-col">
      <Navbar />

      <main className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-md">
          <h1 className="text-3xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">
            Upload Your File
          </h1>
          <form onSubmit={handleSubmit} className="bg-gray-800 shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="apiKey">
                API Key
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-700 border-gray-600 placeholder-gray-400"
                id="apiKey"
                type="text"
                placeholder="Enter your API key"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <div
                onClick={() => fileInputRef.current?.click()}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className="border-2 border-dashed border-gray-600 rounded-lg p-8 text-center cursor-pointer hover:border-indigo-400 transition"
              >
                <p className="text-gray-400">
                  {file ? `Selected file: ${file.name}` : 'Drag and drop a file here, or click to select a file'}
                </p>
              </div>
              <input
                ref={fileInputRef}
                type="file"
                className="hidden"
                onChange={handleFileChange}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition flex items-center"
                type="submit"
              >
                Upload File
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </form>
          {responseMessage.type && (
            <div className={`p-4 rounded-md ${
              responseMessage.type === 'success' ? 'bg-green-900 text-green-400 border border-green-700' :
              responseMessage.type === 'error' ? 'bg-red-900 text-red-400 border border-red-700' :
              'bg-blue-900 text-blue-400 border border-blue-700'
            }`}>
              {responseMessage.type === 'loading' && (
                <div className="flex items-center">
                  <AlertCircle className="animate-spin h-5 w-5 mr-2" />
                  {responseMessage.message}
                </div>
              )}
              {responseMessage.type !== 'loading' && responseMessage.message}
            </div>
          )}
        </div>
      </main>

    </div>
  );
};

export default UploadPage;