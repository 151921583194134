import React from 'react';
import { Cloud, Brain, FileText, BarChart2, FileCheck, Send } from 'lucide-react';

const VeriloWorkflowDiagram = () => {
  const steps = [
    { icon: Cloud, title: "Data Upload" },
    { icon: Brain, title: "AI Transcription" },
    { icon: FileText, title: "Multi-modal Analysis" },
    { icon: BarChart2, title: "Confidence Scoring" },
    { icon: FileCheck, title: "Report Creation" },
    { icon: Send, title: "Delivery" }
  ];

  return (
    <svg viewBox="0 0 800 400" className="w-full h-auto">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#6366f1" />
          <stop offset="100%" stopColor="#a855f7" />
        </linearGradient>
      </defs>
      
      {/* Background */}
      <rect width="100%" height="100%" fill="#1f2937" rx="15" ry="15" />
      
      {/* Connection lines */}
      {steps.map((_, index) => {
        if (index === steps.length - 1) return null;
        const startY = index % 2 === 0 ? 100 : 200;
        const endY = (index + 1) % 2 === 0 ? 100 : 200;
        return (
          <path 
            key={`line-${index}`}
            d={`M${80 + (index * 130)},${startY} C${145 + (index * 130)},${startY} ${145 + (index * 130)},${endY} ${210 + (index * 130)},${endY}`}
            stroke="url(#gradient)" 
            strokeWidth="3" 
            fill="none" 
          />
        );
      })}
      
      {/* Workflow steps */}
      {steps.map((step, index) => (
        <g key={index} transform={`translate(${80 + (index * 130)}, ${index % 2 === 0 ? 100 : 200})`}>
          {/* Icon background circle */}
          <circle r="40" fill="url(#gradient)" />
          
          {/* Render Lucide icon */}
          <g transform="translate(-16, -16)">
            {React.createElement(step.icon, { size: 32, color: "white" })}
          </g>
          
          {/* Step title */}
          <text 
            x="0" 
            y="60" 
            textAnchor="middle" 
            fill="white" 
            fontSize="14"
            fontWeight="bold"
          >
            {step.title}
          </text>
        </g>
      ))}
      
      {/* Verilo logo */}
      <text 
        x="780" 
        y="380" 
        textAnchor="end" 
        fill="white" 
        fontSize="20"
        fontWeight="bold"
        opacity="0.5"
      >
        Verilo
      </text>
    </svg>
  );
};

export default VeriloWorkflowDiagram;