import React from 'react';
import { FileText, AlertTriangle, Shield, Scale, HelpCircle, Briefcase } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar.tsx';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <Navbar />

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-extrabold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">Terms of Service</h1>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <FileText className="mr-2 text-indigo-400" /> Introduction
          </h2>
          <p className="mb-4">
            Welcome to Verilo. These Terms of Service ("Terms") govern your access to and use of Verilo's website, services, and applications (the "Service"). Please read these Terms carefully, and contact us if you have any questions. By using our Service, you agree to be bound by these Terms.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Briefcase className="mr-2 text-indigo-400" /> Use of Service
          </h2>
          <p className="mb-4">Verilo provides an AI-powered interview analysis service. By using our Service, you agree to:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Provide accurate and complete information when creating your account</li>
            <li>Maintain the security of your account and promptly notify Verilo of any unauthorized use</li>
            <li>Use the Service only for lawful purposes and in accordance with these Terms</li>
            <li>Not use the Service in any way that could damage, disable, overburden, or impair Verilo's servers or networks</li>
            <li>Not attempt to gain unauthorized access to any part of the Service</li>
            <li>Not use the Service for any fraudulent or illegal purpose</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <AlertTriangle className="mr-2 text-indigo-400" /> User Content and Conduct
          </h2>
          <p className="mb-4">
            Our Service allows you to upload, submit, and analyze interview recordings and related content ("User Content"). You retain all rights to your User Content, but you grant Verilo a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your User Content for the purpose of providing and improving the Service.
          </p>
          <p className="mb-4">You are solely responsible for your User Content and agree not to upload content that:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Infringes on the intellectual property rights of others</li>
            <li>Contains sensitive personal information of individuals without their consent</li>
            <li>Is defamatory, obscene, or otherwise objectionable</li>
            <li>Violates any applicable law or regulation</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Shield className="mr-2 text-indigo-400" /> Intellectual Property Rights
          </h2>
          <p className="mb-4">
            The Service and its original content, features, and functionality are owned by Verilo and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. You may not copy, modify, create derivative works of, publicly display, publicly perform, republish, or transmit any of the material on our Service without prior written consent.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <HelpCircle className="mr-2 text-indigo-400" /> Disclaimer of Warranties
          </h2>
          <p className="mb-4">
            Verilo provides the Service on an "as is" and "as available" basis. We do not warrant that the Service will be uninterrupted, timely, secure, or error-free. We make no warranties regarding the accuracy, reliability, or effectiveness of the AI analysis provided by the Service.
          </p>
          <p className="mb-4">
            You understand and agree that you use the Service at your own discretion and risk. Verilo disclaims all warranties, express or implied, including warranties of merchantability, fitness for a particular purpose, and non-infringement.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Scale className="mr-2 text-indigo-400" /> Limitation of Liability
          </h2>
          <p className="mb-4">
            To the fullest extent permitted by applicable law, Verilo shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Your use or inability to use the Service</li>
            <li>Any unauthorized access to or use of our servers and/or any personal information stored therein</li>
            <li>Any interruption or cessation of transmission to or from the Service</li>
            <li>Any bugs, viruses, or other harmful components that may be transmitted through the Service</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Indemnification</h2>
          <p className="mb-4">
            You agree to defend, indemnify, and hold harmless Verilo and its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Service or your violation of these Terms.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
          <p className="mb-4">
            These Terms shall be governed and construed in accordance with the laws of State of Utah, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Changes to Terms</h2>
          <p className="mb-4">
            We reserve the right to modify or replace these Terms at any time. We will provide notice of any material changes by posting the new Terms on this page and updating the "Last Updated" date. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p className="mb-4">
            If you have any questions about these Terms, please contact us at:
          </p>
          <p className="mb-2">Email: info@verilo.com</p>
          {/* <p>Phone: +1 (555) 123-4567</p> */}
        </section>

        <p className="text-sm text-gray-400 text-center">
          Last Updated: August 22, 2024
        </p>
      </main>


    </div>
  );
};

export default TermsOfService;