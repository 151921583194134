import React, { useState } from 'react';
import { AlertCircle, DollarSign, Users } from 'lucide-react';
import { Link } from 'react-router-dom';

const ValueCalculator = () => {
  const [averageSalary, setAverageSalary] = useState('');
  const [hiresPerYear, setHiresPerYear] = useState('');
  const [potentialSavings, setPotentialSavings] = useState(null);

  const calculateSavings = () => {
    if (averageSalary && hiresPerYear && !isNaN(averageSalary) && !isNaN(hiresPerYear)) {
      const avgSalary = parseFloat(averageSalary);
      const hires = parseFloat(hiresPerYear);
      const minSavings = avgSalary * 3 * hires * 0.21;
      const maxSavings = avgSalary * 4 * hires * 0.21;
      setPotentialSavings({ min: minSavings, max: maxSavings });
    } else {
      setPotentialSavings(null);
    }
  };

  return (
    <div className="bg-gray-900 rounded-lg p-8 shadow-lg">
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-100">Explore Potential Value with Verilo</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-6">
          <div>
            <label htmlFor="averageSalary" className="block text-sm font-medium text-gray-300 mb-2">
              Average annual salary for positions you're hiring:
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                name="averageSalary"
                id="averageSalary"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 pr-12 sm:text-sm border-gray-600 rounded-md bg-gray-800 text-white"
                placeholder="0.00"
                value={averageSalary}
                onChange={(e) => setAverageSalary(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label htmlFor="hiresPerYear" className="block text-sm font-medium text-gray-300 mb-2">
              Estimated number of employees hired per year:
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Users className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                name="hiresPerYear"
                id="hiresPerYear"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 pr-12 sm:text-sm border-gray-600 rounded-md bg-gray-800 text-white"
                placeholder="0"
                value={hiresPerYear}
                onChange={(e) => setHiresPerYear(e.target.value)}
              />
            </div>
          </div>
          <button
            onClick={calculateSavings}
            className="w-full bg-gradient-to-r from-indigo-500 to-purple-600 text-white font-semibold py-3 px-4 rounded-md hover:from-indigo-600 hover:to-purple-700 transition shadow-md"
          >
            Estimate Potential Savings
          </button>
        </div>
        <div className="bg-gray-800 rounded-lg p-6 flex flex-col justify-center items-center">
          {potentialSavings ? (
            <>
              <p className="text-xl font-semibold text-gray-100 mb-2">Estimated potential value range:</p>
              <p className="text-3xl font-bold text-indigo-400 mb-4">
                ${potentialSavings.min.toLocaleString(undefined, {maximumFractionDigits:0})} - ${potentialSavings.max.toLocaleString(undefined, {maximumFractionDigits:0})}
              </p>
              <p className="text-sm text-gray-400 text-center">
                This is a rough estimate. Contact us for a personalized analysis.
              </p>
            </>
          ) : (
            <p className="text-xl text-gray-400 text-center">
              Enter your details and click "Estimate Potential Value" to see your potential savings.
            </p>
          )}
        </div>
      </div>
      <div className="mt-8 bg-gray-800 border border-gray-700 rounded-md p-4 text-sm text-gray-400">
        <div className="flex items-start mb-2">
          <AlertCircle className="h-5 w-5 text-yellow-500 mr-2 flex-shrink-0 mt-0.5" />
          <p className="font-semibold text-yellow-500">Important Disclaimer</p>
        </div>
        <p className="mb-2">
          This calculator provides a rough estimate based on industry averages and is for illustrative purposes only. It is not a guarantee of results or savings.
        </p>
        <p className="mb-2">
          The estimate considers:
          <ul className="list-disc list-inside ml-4 mt-1">
            <li>Potential cost of a bad hire (estimated at 3-4 times annual salary)*</li>
            <li>Verilo's estimated 21% accuracy improvement over typical human judgment</li>
          </ul>
        </p>
        <p>
          Actual results may vary significantly based on your specific circumstances, industry, hiring processes, and other factors.
        </p>

        <p className="mt-2">
          * as reported by SHRM
        </p>
      </div>
      <div className="mt-6 text-center">
        <p className="text-lg font-semibold mb-2 text-gray-300">Want a more accurate estimate?</p>
        <Link to="/contact" className="text-indigo-400 hover:text-indigo-300 underline">
          Contact us for a personalized consultation
        </Link>
      </div>
    </div>
  );
};

export default ValueCalculator;