import React from 'react';
import { ArrowRight, Brain, Zap, BarChart2, Users, Flag, Shield, Mic, FileText, PieChart, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar.tsx';
import InteractiveTranscript from './InteractiveTranscript.tsx';
import AccuracyComparisonComponent from './AccuracyComparisonComponent.tsx';
import Footer from './Footer.tsx';
import VeriloWorkflowDiagram from './WorkflowDiagram.tsx';

const ProductPage = () => {
  const features = [
    {
      icon: Brain,
      title: "Advanced AI Analysis",
      description: "Our proprietary ML models analyze speech patterns, tone, and word choice to assess candidate confidence with unparalleled accuracy."
    },
    {
      icon: Clock,
      title: "Efficient Processing",
      description: "Receive comprehensive analysis within hours of uploading your interview recordings, allowing for timely decision-making."
    },
    {
      icon: BarChart2,
      title: "Comprehensive Reports",
      description: "Receive detailed reports with confidence scores, potential red flags, and overall candidate assessment to streamline your hiring process."
    },
    {
      icon: Users,
      title: "Team Collaboration",
      description: "Share interview insights seamlessly with your hiring team, ensuring everyone is aligned when making crucial decisions."
    }
  ];

  const modelFeatures = [
    {
      icon: Mic,
      title: "Audio Analysis",
      description: "Our AI processes vocal cues such as pitch, tone, and speech rate to detect subtle indicators of confidence or uncertainty."
    },
    {
      icon: FileText,
      title: "Natural Language Processing",
      description: "Advanced NLP algorithms analyze word choice, sentence structure, and linguistic patterns to gauge the candidate's level of assurance."
    },
    {
      icon: PieChart,
      title: "Multi-modal Integration",
      description: "By combining audio and text analysis, our model provides a comprehensive and nuanced assessment of candidate confidence."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <Navbar />
  
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <section className="py-20 md:py-32 text-center">
          <h1 className="text-5xl md:text-6xl font-extrabold mb-6 leading-tight text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">
            AI-Powered Confidence Analysis for Smarter Hiring
          </h1>
          <p className="text-xl md:text-2xl text-gray-300 mb-12 max-w-3xl mx-auto">
            Uncover hidden insights and make data-driven hiring decisions with Verilo's cutting-edge AI technology
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <Link to="/pricing" className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-lg font-semibold py-3 px-8 rounded-lg hover:from-indigo-600 hover:to-purple-700 transition flex items-center justify-center shadow-lg">
              Start Free Trial
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
            <button className="border border-indigo-500 text-indigo-400 text-lg font-semibold py-3 px-8 rounded-lg hover:bg-indigo-500 hover:text-white transition">
              Schedule a Demo
            </button>
          </div>
        </section>
  
        <section className="py-20">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-gray-100">Why Verilo Outperforms Human Judgment</h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <AccuracyComparisonComponent logoSrc="veriloLogo512.png" />
            <div>
              <ul className="space-y-6">
                <li className="flex items-start">
                  <Shield className="h-6 w-6 text-indigo-400 mr-4 flex-shrink-0 mt-1" />
                  <span className="text-gray-300 text-lg">Analyzes 100's of subtle speech patterns and behavioral cues that humans often miss</span>
                </li>
                <li className="flex items-start">
                  <Shield className="h-6 w-6 text-indigo-400 mr-4 flex-shrink-0 mt-1" />
                  <span className="text-gray-300 text-lg">Consistently outperforms human interviewers in assessing candidate confidence</span>
                </li>
                <li className="flex items-start">
                  <Shield className="h-6 w-6 text-indigo-400 mr-4 flex-shrink-0 mt-1" />
                  <span className="text-gray-300 text-lg">Eliminates unconscious bias for fair and objective candidate evaluations</span>
                </li>
                <li className="flex items-start">
                  <Shield className="h-6 w-6 text-indigo-400 mr-4 flex-shrink-0 mt-1" />
                  <span className="text-gray-300 text-lg">Provides consistent evaluation criteria across all interviews, regardless of interviewer fatigue</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
  
        <section className="py-20">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-100">Experience AI-Driven Interview Analysis</h2>
          <InteractiveTranscript />
        </section>
  
        <section className="py-20">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-gray-100">The Science Behind Verilo</h2>
          <div className="bg-gray-800 rounded-lg p-8 shadow-xl mb-12">
            <p className="text-xl text-gray-300 mb-6">
              Verilo's groundbreaking confidence detection model leverages cutting-edge AI technology to revolutionize the hiring process. Our sophisticated system combines audio and text analysis to identify key indicators that, based on extensive research, accurately highlight confidence levels in interviews.
            </p>
            <p className="text-xl text-gray-300 mb-6">
              By processing hundreds of data points from your uploaded interviews, Verilo provides hiring managers with unprecedented insights into candidate confidence, enabling more informed decision-making and ultimately leading to stronger, more successful hires.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {modelFeatures.map((feature, index) => (
              <div key={index} className="bg-gray-800 rounded-lg p-6 shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
                <div className="flex items-center mb-4">
                  <div className="bg-gradient-to-br from-indigo-500 to-purple-600 rounded-full p-3 mr-4">
                    <feature.icon className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-100">{feature.title}</h3>
                </div>
                <p className="text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="py-20">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-100">How Verilo Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div className="bg-gray-800 rounded-lg p-8 shadow-xl">
              <ol className="list-decimal list-inside space-y-4 text-gray-300">
                <li><span className="font-semibold text-indigo-400">Data Upload:</span> Upload your interview recordings through our secure platform.</li>
                <li><span className="font-semibold text-indigo-400">AI Transcription:</span> Our advanced AI transcribes and analyzes the interview content.</li>
                <li><span className="font-semibold text-indigo-400">Multi-modal Analysis:</span> The system processes both audio and text data to identify confidence indicators.</li>
                <li><span className="font-semibold text-indigo-400">Confidence Scoring:</span> Each response is assigned a confidence score based on our proprietary algorithm.</li>
                <li><span className="font-semibold text-indigo-400">Report Creation:</span> A comprehensive report is generated, highlighting key findings and recommendations.</li>
                <li><span className="font-semibold text-indigo-400">Delivery:</span> The final report is delivered to you, via email, ready for your review.</li>
              </ol>
            </div>
            <div className="flex justify-center">
              <VeriloWorkflowDiagram />
            </div>
          </div>
        </section>

        <section className="py-20">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-100">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-800 rounded-lg p-6 shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
                <div className="flex items-center mb-4">
                  <div className="bg-gradient-to-br from-indigo-500 to-purple-600 rounded-full p-3 mr-4">
                    <feature.icon className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-100">{feature.title}</h3>
                </div>
                <p className="text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>
  
        <section className="py-20 text-center">
          <h2 className="text-3xl font-bold mb-6 text-gray-100">Ready to Transform Your Hiring Process?</h2>
          <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
            Join the companies leveraging AI to build stronger teams and reduce hiring costs.
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <Link to="/pricing" className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-lg font-semibold py-3 px-8 rounded-lg hover:from-indigo-600 hover:to-purple-700 transition flex items-center justify-center shadow-lg">
              Start Free Trial
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
            <button className="border border-indigo-500 text-indigo-400 text-lg font-semibold py-3 px-8 rounded-lg hover:bg-indigo-500 hover:text-white transition">
              Schedule a Demo
            </button>
          </div>
        </section>
      </main>
  
      <Footer />
    </div>
  );
};

export default ProductPage;