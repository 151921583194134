import React from 'react';
import { User } from 'lucide-react';

const AccuracyComparisonComponent = ({ logoSrc }) => {
  return (
    <div className="bg-gray-900 p-8 rounded-lg shadow-lg">
      <h3 className="text-2xl font-bold text-center mb-8 text-gray-100">Accuracy Comparison</h3>
      <div className="flex justify-around items-center mb-8">
        <div className="flex flex-col items-center">
          <div className="relative mb-4 w-32 h-32">
            <div className="w-full h-full rounded-full bg-gray-800 flex items-center justify-center">
              <div className="w-24 h-24 rounded-full border-[12px] border-gray-700"></div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full">
              <svg className="w-full h-full" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="44"
                  fill="none"
                  stroke="#EC4899"
                  strokeWidth="12"
                  strokeDasharray="276.46"
                  strokeDashoffset="141"
                  transform="rotate(-90 50 50)"
                />
              </svg>
            </div>
            <User className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-purple-400 w-12 h-12" />
          </div>
          <p className="text-lg font-semibold text-gray-300">Human Judgment</p>
          <p className="text-3xl font-bold text-pink-500">49%</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="relative mb-4 w-32 h-32">
            <div className="w-full h-full rounded-full bg-gray-800 flex items-center justify-center">
              <div className="w-24 h-24 rounded-full border-[12px] border-gray-700"></div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full">
              <svg className="w-full h-full" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="44"
                  fill="none"
                  stroke="#8B5CF6"
                  strokeWidth="12"
                  strokeDasharray="276.46"
                  strokeDashoffset="83"
                  transform="rotate(-90 50 50)"
                />
              </svg>
            </div>
            <img 
              src={logoSrc} 
              alt="Verilo Logo" 
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 object-contain"
            />
          </div>
          <p className="text-lg font-semibold text-gray-300">Verilo AI</p>
          <p className="text-3xl font-bold text-purple-500">70%</p>
        </div>
      </div>
      <div className="bg-gray-800 p-6 rounded-lg text-center">
        <p className="text-xl text-gray-100 mb-2">
          Verilo AI outperforms human judgment by 
        </p>
        <p className="text-4xl font-bold text-purple-400 mb-2">43% margin</p>
        <p className="text-lg text-gray-300">
          Delivering unparalleled accuracy in interview analysis
        </p>
      </div>
      <div className="mt-6 text-center">
        <p className="text-gray-400 text-sm">
          *Based on comparative analysis in controlled testing environments
        </p>
      </div>
    </div>
  );
};

export default AccuracyComparisonComponent;