import React, { useState } from 'react';
import { Flag, ChevronDown, ChevronUp, Info } from 'lucide-react';

const InteractiveTranscript = () => {
  const [expandedQuestions, setExpandedQuestions] = useState([0]);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const transcriptData = [
    {
      question: "Can you walk me through the machine learning project you mentioned in your resume?",
      answer: "Certainly. At TechInnovate, I worked on a predictive maintenance project for manufacturing equipment. We used a combination of sensor data and historical maintenance records to build a model that could predict potential failures. The project involved data preprocessing, feature engineering, and we experimented with different algorithms before settling on a gradient boosting model. Our solution helped reduce unplanned downtime by about 15% in the pilot phase.",
      predictedConfidence: false,
      analysis: "While the answer seems knowledgeable, the AI detects subtle cues of uncertainty, possibly indicating the candidate's role or understanding of the project might not be as deep as implied."
    },
    {
      question: "You mentioned experience with Kubernetes in your application. How have you used it in your previous roles?",
      answer: "I've used Kubernetes in my last two positions. It was primarily for container orchestration in our microservices architecture. I've set up and managed clusters, handled deployments, and worked on scaling our applications. I've also dealt with issues like load balancing and service discovery. It's been a crucial part of our infrastructure and has really helped with our CI/CD pipeline efficiency.",
      predictedConfidence: true,
      analysis: "The candidate's response indicates a high level of confidence and familiarity with Kubernetes, aligning with their claimed experience."
    },
    {
      question: "Tell me about a time when you had to learn a new technology quickly for a project.",
      answer: "In my last role, we had to integrate a new graph database for a recommendation engine. I hadn't worked with graph databases before, so I had to learn quickly. I spent a week going through documentation, online courses, and practicing with sample datasets. By the end of the month, I had successfully implemented the database into our system, and it significantly improved our recommendation accuracy.",
      predictedConfidence: false,
      analysis: "Despite a detailed answer, the AI detects subtle indicators of lower confidence, possibly suggesting the learning process or implementation was more challenging than the candidate presents."
    },
    {
      question: "Your resume mentions leading a team through a critical product launch. Can you elaborate on that experience?",
      answer: "Of course. This was for our company's flagship product update. I led a team of eight developers, and we were working on a tight six-month timeline. We had to coordinate closely with product management, QA, and customer support teams. There were definitely challenges, like last-minute feature requests and some integration issues, but we managed to deliver on time. The launch was successful, and we saw a 20% increase in user engagement in the first month.",
      predictedConfidence: true,
      analysis: "The candidate displays high confidence in describing their leadership experience, providing specific details and outcomes that support their claims."
    }
  ];

  const toggleQuestion = (index) => {
    setExpandedQuestions(prev => 
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    );
  };

  return (
    <div className="bg-gray-800 rounded-lg p-6 shadow-xl max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-2xl font-semibold text-gray-100">Interactive Transcript Analysis</h3>
        <button 
          onClick={() => setShowInfoModal(true)}
          className="text-indigo-400 hover:text-indigo-300 flex items-center"
        >
          <Info className="h-5 w-5 mr-1" />
          How to read this
        </button>
      </div>
      
      {transcriptData.map((item, index) => (
        <div key={index} className="mb-6 bg-gray-700 rounded-lg overflow-hidden">
          <div 
            className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-600"
            onClick={() => toggleQuestion(index)}
          >
            <h4 className="text-lg font-medium text-gray-100">{item.question}</h4>
            {expandedQuestions.includes(index) ? (
              <ChevronUp className="h-5 w-5 text-gray-300" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-300" />
            )}
          </div>
          
          {expandedQuestions.includes(index) && (
            <div className="p-4 border-t border-gray-600">
              <p className="text-gray-300 mb-4">{item.answer}</p>
              <div className="flex items-center">
                <Flag
                  className={`h-5 w-5 ${
                    item.predictedConfidence ? 'text-green-400' : 'text-red-400'
                  }`}
                  fill={item.predictedConfidence ? 'rgb(74 222 128)' : 'rgb(248 113 113)'}
                />
                <span className="ml-2 text-sm text-gray-400">
                  {item.predictedConfidence ? 'Predicted Confident' : 'Predicted Less Confident'}
                </span>
              </div>
            </div>
          )}
        </div>
      ))}

      {showInfoModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg max-w-md">
            <h4 className="text-xl font-semibold mb-4 text-gray-100">How to Read the Transcript</h4>
            <ul className="list-disc list-inside text-gray-300 mb-4">
              <li>Green flag: AI predicts the candidate is confident in their response</li>
              <li>Red flag: AI predicts the candidate is less confident in their response</li>
            </ul>
            <p className="text-gray-300 mb-4">
              Verilo's AI analyzes subtle cues in candidates' responses that might not be immediately apparent to human interviewers. This can provide valuable insights into a candidate's true level of experience and expertise.
            </p>
            <button 
              onClick={() => setShowInfoModal(false)}
              className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600 transition"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InteractiveTranscript;