import React, { useState } from 'react';
import { ArrowRight, Check, HelpCircle, Menu, X, Shield, AlertCircle } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from './Navbar.tsx';
import Footer from './Footer.tsx';

const PricingPage = () => {
  const [billingCycle, setBillingCycle] = useState('annual');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate = useNavigate();

  const pricingTiers = [
    {
      name: 'Basic',
      monthlyPrice: 624,
      annualPrice: 5988,
      features: [
        'Up to 8 hours of interview audio processing',
        'Basic reporting',
        'Email support',
        'Secure data encryption',
      ],
      cta: 'Start Basic Plan',
      highlighted: false,
    },
    {
      name: 'Professional',
      monthlyPrice: 1249,
      annualPrice: 11988,
      features: [
        'Up to 25 hours of interview audio processing',
        'Discounted pricing on over-limit requests',
        'Advanced reporting and analytics*',
        'Integration with popular ATS systems*',
        'Priority support',
      ],
      cta: 'Start Professional Plan',
      highlighted: true,
    },
    {
      name: 'Enterprise',
      monthlyPrice: null,
      annualPrice: null,
      features: [
        'Custom audio processing limits',
        'Full suite of features including custom integrations*',
        'Dedicated account manager*',
        'Tailored solutions based on client needs',
        'Phone and email support',
      ],
      cta: 'Contact Sales',
      highlighted: false,
    },
  ];

  const handlePlanSelection = (planName) => {
    setSelectedPlan(planName);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmSelection = () => {
    navigate('/contact', { state: { selectedPlan } });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <Navbar />

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-extrabold mb-6 leading-tight text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">
            Beta Pricing Plans
          </h1>
          <p className="text-xl text-gray-300 mb-8">Choose the plan that best fits your hiring needs</p>
          <div className="bg-indigo-600 text-white px-4 py-2 rounded-md inline-block">
            <AlertCircle className="inline-block mr-2" />
            Beta Offer: 3-Month Free Trial for All Plans
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-16">
          {pricingTiers.map((tier, index) => (
            <div
              key={index}
              className={`bg-gray-800 rounded-lg p-8 flex flex-col h-full shadow-lg hover:shadow-xl transition ${
                tier.highlighted ? 'ring-2 ring-indigo-500 transform lg:scale-105 z-10' : ''
              }`}
            >
              <h2 className="text-2xl font-bold mb-4 text-gray-100">{tier.name}</h2>
              <div className="mb-6">
                {tier.monthlyPrice !== null ? (
                  <>
                    <span className="text-4xl font-bold text-gray-100">
                      ${billingCycle === 'monthly' ? tier.monthlyPrice : Math.round(tier.annualPrice / 12)}
                    </span>
                    <span className="text-xl text-gray-300">/month</span>
                    {billingCycle === 'annual' && (
                      <div className="text-sm text-indigo-400 mt-1">
                        billed at ${tier.annualPrice} annually
                      </div>
                    )}
                  </>
                ) : (
                  <span className="text-2xl font-bold text-gray-100">Custom Pricing</span>
                )}
              </div>
              <ul className="mb-8 flex-grow">
                {tier.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-start mb-3">
                    <Check className="h-5 w-5 text-indigo-400 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">{feature}</span>
                    {feature.includes('*') && (
                      <HelpCircle className="h-4 w-4 text-indigo-400 ml-1 cursor-help" />
                    )}
                  </li>
                ))}
              </ul>
              <button 
                className="w-full bg-gradient-to-r from-indigo-500 to-purple-600 text-white font-semibold py-3 px-4 rounded-md hover:from-indigo-600 hover:to-purple-700 transition flex items-center justify-center"
                onClick={() => handlePlanSelection(tier.name)}
              >
                {tier.cta}
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          ))}
        </div>

        {/* Beta Information */}
        <div className="bg-gray-800 rounded-lg p-8 shadow-xl mb-16">
          <h2 className="text-2xl font-bold mb-4 text-gray-100">Looking for a custom solution?</h2>
          <p className="text-gray-300 mb-6">
            We offer tailored plans for large enterprises or unique use cases. Contact our sales team to
            discuss your specific needs and get a personalized quote.
          </p>
          <Link 
              to="/contact" className="bg-indigo-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-indigo-600 transition">
            Contact Sales
          </Link>
        </div>

        <div className="mb-16">
          <h2 className="text-2xl font-bold mb-8 text-center text-gray-100">Frequently Asked Questions</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-800 rounded-lg p-6">
              <h3 className="font-semibold mb-2 text-gray-100">What does the asterisk (*) mean?</h3>
              <p className="text-gray-300">Features marked with an asterisk (*) are coming soon. We're constantly improving our platform to provide you with the best possible experience.</p>
            </div>
            <div className="bg-gray-800 rounded-lg p-6">
              <h3 className="font-semibold mb-2 text-gray-100">How does the 3-month free trial work?</h3>
              <p className="text-gray-300">During our beta phase, all new clients get a 3-month free trial of their chosen plan. After the trial, you can choose to continue with a paid subscription or downgrade/cancel.</p>
            </div>
            <div className="bg-gray-800 rounded-lg p-6">
              <h3 className="font-semibold mb-2 text-gray-100">What happens if I exceed my audio processing limit?</h3>
              <p className="text-gray-300">If you exceed your plan's audio processing limit, you'll be charged a pro-rated amount for the additional usage. Professional and Enterprise plans offer discounted rates for over-limit requests.</p>
            </div>
            <div className="bg-gray-800 rounded-lg p-6">
              <h3 className="font-semibold mb-2 text-gray-100">Can I switch plans during the beta?</h3>
              <p className="text-gray-300">Yes, you can switch between plans at any time during the beta period. Your 3-month free trial will continue from the date you first signed up, regardless of plan changes.</p>
            </div>
          </div>
        </div>

        <div className="text-center">
          <h2 className="text-3xl font-bold mb-6 text-gray-100">Ready to get started?</h2>
          <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
            Join the companies leveraging AI to build stronger teams and reduce hiring costs.
          </p>
          <button 
            onClick={() => handlePlanSelection('Beta Access')}
            className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-lg font-semibold py-3 px-8 rounded-lg hover:from-indigo-600 hover:to-purple-700 transition flex items-center justify-center mx-auto"
          >
            Start Your Free Trial
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>
      </main>

      {/* Beta Program Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-8 rounded-lg max-w-md">
            <h3 className="text-2xl font-bold mb-4 text-gray-100">Beta Program Information</h3>
            <p className="text-gray-300 mb-4">
              Thank you for your interest in our {selectedPlan} plan. As we're currently in beta, we're offering a 3-month free trial for all plans.
            </p>
            <p className="text-gray-300 mb-4">
              To proceed, you'll be directed to our contact form where you can request access to the beta program. We'll be in touch shortly to set up your account.
            </p>
            <div className="flex justify-end space-x-4">
              <button 
                className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700 transition"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button 
                className="bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition"
                onClick={handleConfirmSelection}
              >
                Proceed to Contact
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default PricingPage;