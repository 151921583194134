import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop.tsx';
import Footer from './components/Footer.tsx';
import LandingPage from './components/LandingPage.tsx';
import BenefitsPage from './components/BenefitsPage.tsx';
import ProductPage from './components/ProductPage.tsx';
import PricingPage from './components/PricingPage.tsx';
import ContactPage from './components/ContactPage.tsx';
import UploadPage from './components/UploadPage.tsx';
import PrivacyPolicyPage from './components/PrivacyPolicyPage.tsx';
import TermsOfServicePage from './components/TermsOfServicePage.tsx';
import CookiePolicy from './components/CookiePolicyPage.tsx';
import EventLandingPage from './components/EventLandingPage.tsx';
import EventFormPage from './components/EventFormPage.tsx';
import { Helmet } from 'react-helmet';
import './App.css';

function App() {
  return (
    <Router>
      <Helmet>
        <title>Verilo - AI-Powered Interview Analysis</title>
        <link rel="icon" href="veriloLogo180.png" />
        <link rel="apple-touch-icon" href="veriloLogo180.png" />
        <meta name="description" content="Boost interview success with AI-powered confidence analysis from Verilo" />
        <meta property="og:title" content="Verilo - AI-Powered Interview Analysis" />
        <meta property="og:description" content="Improve your hiring process with Verilo's advanced AI technology for interview analysis" />
        <meta property="og:image" content="https://www.verilo.co/veriloLogo512.png" />
        <meta property="og:url" content="https://www.verilo.co" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Verilo - AI-Powered Interview Analysis" />
        <meta name="twitter:description" content="Improve your hiring process with Verilo's advanced AI technology for interview analysis" />
        <meta name="twitter:image" content="https://www.verilo.co/veriloLogo512.png" />
      </Helmet>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/benefits" element={<BenefitsPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/upload" element={<UploadPage />} />
        <Route path="/event" element={<EventLandingPage />} />
        <Route path="/event/form" element={<EventFormPage />} />
        {/* Add other routes here */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;