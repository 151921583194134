import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="sticky top-0 z-50 bg-gray-800 bg-opacity-90 backdrop-filter backdrop-blur-lg">
  <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
    <Link to="/" className="flex items-center">
      <div className="w-10 h-10 mr-3 flex items-center justify-center">
        <img 
          src="veriloLogo512.png" 
          alt="Verilo Logo" 
          className="w-full h-full object-contain"
        />
      </div>
      <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">Verilo</h1>
    </Link>
    <nav className="hidden md:block">
      <ul className="flex space-x-8">
        <li><Link to="/" className="text-gray-300 hover:text-indigo-400 transition">Home</Link></li>
        <li><Link to="/benefits" className="text-gray-300 hover:text-indigo-400 transition">Benefits</Link></li>
        <li><Link to="/product" className="text-gray-300 hover:text-indigo-400 transition">Product</Link></li>
        <li><Link to="/pricing" className="text-gray-300 hover:text-indigo-400 transition">Pricing</Link></li>
        <li><Link to="/contact" className="text-gray-300 hover:text-indigo-400 transition">Contact</Link></li>
      </ul>
    </nav>
    <button className="md:hidden" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
      {mobileMenuOpen ? <X className="h-6 w-6 text-gray-300" /> : <Menu className="h-6 w-6 text-gray-300" />}
    </button>
  </div>
  {mobileMenuOpen && (
    <div className="md:hidden bg-gray-800">
      <ul className="px-4 py-2">
        <li><Link to="/" className="block py-2 text-gray-300 hover:text-indigo-400 transition">Home</Link></li>
        <li><Link to="/benefits" className="block py-2 text-gray-300 hover:text-indigo-400 transition">Benefits</Link></li>
        <li><Link to="/product" className="block py-2 text-gray-300 hover:text-indigo-400 transition">Product</Link></li>
        <li><Link to="/pricing" className="block py-2 text-gray-300 hover:text-indigo-400 transition">Pricing</Link></li>
        <li><Link to="/contact" className="block py-2 text-gray-300 hover:text-indigo-400 transition">Contact</Link></li>
      </ul>
    </div>
  )}
</header>
  );
};

export default Navbar;