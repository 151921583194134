import React from 'react';
import { Link } from 'react-router-dom';
import { Shield, CheckCircle, Linkedin, Twitter, Facebook } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center mb-4">
            <div className="w-10 h-10 mr-3 flex items-center justify-center">
                <img 
                src="veriloLogo512.png" 
                alt="Verilo Logo" 
                className="w-full h-full object-contain"
                />
            </div>
              <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">Verilo</h2>
            </div>
            <p className="text-sm">Revolutionizing interviews with AI-powered confidence analysis.</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-indigo-400 transition">Home</Link></li>
              <li><Link to="/benefits" className="hover:text-indigo-400 transition">Benefits</Link></li>
              <li><Link to="/product" className="hover:text-indigo-400 transition">Product</Link></li>
              <li><Link to="/pricing" className="hover:text-indigo-400 transition">Pricing</Link></li>
              <li><Link to="/contact" className="hover:text-indigo-400 transition">Contact</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/privacy-policy" className="hover:text-indigo-400 transition">Privacy Policy</Link></li>
              <li><Link to="/terms-of-service" className="hover:text-indigo-400 transition">Terms of Service</Link></li>
              <li><Link to="/cookie-policy" className="hover:text-indigo-400 transition">Cookie Policy</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Connect</h3>
            <div className="flex space-x-4">
              <a href="https://www.linkedin.com/company/verilo-ai/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-indigo-400 transition">
                <Linkedin className="h-6 w-6" />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center space-x-4 mb-4 md:mb-0">
            <div className="flex items-center">
              <Shield className="h-5 w-5 text-indigo-400 mr-2" />
              <span className="text-sm">256-bit Encryption</span>
            </div>
            {/* <div className="flex items-center">
              <CheckCircle className="h-5 w-5 text-indigo-400 mr-2" />
              <span className="text-sm">GDPR Compliant</span>
            </div> */}
          </div>
          <p className="text-sm">&copy; 2024 Verilo. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;