import React from 'react';
import { Shield, Lock, Eye, Trash2, Download, RefreshCw } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar.tsx';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <Navbar />

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-extrabold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">Privacy Policy</h1>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Shield className="mr-2 text-indigo-400" /> Introduction
          </h2>
          <p className="mb-4">
            At Verilo, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our AI-powered interview analysis service.
          </p>
          <p>
            By using Verilo, you agree to the practices described in this policy. We encourage you to read this document carefully to understand our approach to handling your information.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Lock className="mr-2 text-indigo-400" /> Information We Collect
          </h2>
          <p className="mb-4">We collect the following types of information:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Personal Information: Name, email address, and other contact details you provide.</li>
            <li>Account Information: Login credentials and preferences.</li>
            <li>Interview Data: Audio recordings, transcripts, and analysis results from interviews.</li>
            <li>Usage Information: How you interact with our service, including features used and time spent.</li>
            <li>Device Information: IP address, browser type, and operating system.</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Eye className="mr-2 text-indigo-400" /> How We Use Your Information
          </h2>
          <p className="mb-4">We use your information for the following purposes:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Provide and improve our AI-powered interview analysis service.</li>
            <li>Personalize your experience and offer tailored insights.</li>
            <li>Communicate with you about your account and our services.</li>
            <li>Analyze usage patterns to enhance our platform.</li>
            <li>Ensure the security and integrity of our service.</li>
            <li>Comply with legal obligations and enforce our terms of service.</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <RefreshCw className="mr-2 text-indigo-400" /> Data Sharing and Disclosure
          </h2>
          <p className="mb-4">We may share your information in the following circumstances:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>With your consent or at your direction.</li>
            <li>With service providers who assist in operating our platform.</li>
            <li>To comply with legal obligations or respond to lawful requests.</li>
            <li>In connection with a merger, acquisition, or sale of assets.</li>
          </ul>
          <p>We do not sell your personal information to third parties.</p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Shield className="mr-2 text-indigo-400" /> Data Security
          </h2>
          <p className="mb-4">
            We implement robust security measures to protect your data, including:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Encryption of data in transit and at rest.</li>
            <li>Regular security audits and vulnerability assessments.</li>
            <li>Strict access controls and authentication procedures.</li>
            <li>Employee training on data protection and privacy practices.</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Download className="mr-2 text-indigo-400" /> Your Rights and Choices
          </h2>
          <p className="mb-4">You have the right to:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Access and update your personal information.</li>
            <li>Request deletion of your data (subject to legal retention requirements).</li>
            <li>Object to or restrict certain data processing activities.</li>
            <li>Data portability (receive your data in a structured, machine-readable format).</li>
            <li>Withdraw consent for optional data processing at any time.</li>
          </ul>
          <p>
            To exercise these rights, please contact us at privacy@verilo.com.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Trash2 className="mr-2 text-indigo-400" /> Data Retention
          </h2>
          <p className="mb-4">
            We retain your personal information for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce our agreements. The retention period may vary depending on the type of data and applicable laws.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Children's Privacy</h2>
          <p className="mb-4">
            Verilo's services are not intended for use by children under the age of 16. We do not knowingly collect personal information from children. If you believe we have inadvertently collected data from a child, please contact us immediately.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">International Data Transfers</h2>
          <p className="mb-4">
            Your information may be transferred to and processed in countries other than your own. We ensure appropriate safeguards are in place to protect your data in compliance with applicable laws.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Changes to This Policy</h2>
          <p className="mb-4">
            We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website and updating the "Last Updated" date.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p className="mb-4">
            If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:
          </p>
          <p className="mb-2">Email: info@verilo.com</p>
          {/* <p className="mb-2">Address: 123 AI Avenue, Tech City, TC 12345, United States</p>
          <p>Phone: +1 (555) 123-4567</p> */}
        </section>

        <p className="text-sm text-gray-400 text-center">
          Last Updated: August 22, 2024
        </p>
      </main>

      
    </div>
  );
};

export default PrivacyPolicy;