import React from 'react';
import { Cookie, Info, Settings, Shield, ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar.tsx';

const CookiePolicy = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <Navbar />

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-extrabold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">Cookie Policy</h1>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Info className="mr-2 text-indigo-400" /> Introduction
          </h2>
          <p className="mb-4">
            This Cookie Policy explains how Verilo ("we", "us", or "our") uses cookies and similar technologies to recognize you when you visit our website and use our AI-powered interview analysis service ("Service"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
          </p>
          <p className="mb-4">
            By continuing to use our Service, you are agreeing to our use of cookies as described in this Cookie Policy.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Cookie className="mr-2 text-indigo-400" /> What are Cookies?
          </h2>
          <p className="mb-4">
            Cookies are small data files that are placed on your computer or mobile device when you visit a website. They are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.
          </p>
          <p className="mb-4">
            Cookies set by the website owner (in this case, Verilo) are called "first-party cookies". Cookies set by parties other than the website owner are called "third-party cookies". Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics).
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Settings className="mr-2 text-indigo-400" /> How We Use Cookies
          </h2>
          <p className="mb-4">We use first-party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Service to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies enable us to track and target the interests of our users to enhance the experience on our Service. Third parties serve cookies through our Service for analytics and other purposes. This is described in more detail below.</p>
          <p className="mb-4">The specific types of first and third-party cookies served through our Service and the purposes they perform are described below:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li><strong>Essential Cookies:</strong> These cookies are strictly necessary to provide you with services available through our Service and to use some of its features, such as access to secure areas.</li>
            <li><strong>Performance and Functionality Cookies:</strong> These cookies are used to enhance the performance and functionality of our Service but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.</li>
            <li><strong>Analytics and Customization Cookies:</strong> These cookies collect information that is used either in aggregate form to help us understand how our Service is being used or how effective our marketing campaigns are, or to help us customize our Service for you.</li>
            <li><strong>Advertising Cookies:</strong> These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Shield className="mr-2 text-indigo-400" /> Your Choices Regarding Cookies
          </h2>
          <p className="mb-4">
            If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.
          </p>
          <p className="mb-4">
            Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>For the Chrome web browser, please visit this page from Google: <a href="https://support.google.com/accounts/answer/32050" target="_blank" rel="noopener noreferrer" className="text-indigo-400 hover:text-indigo-300">https://support.google.com/accounts/answer/32050</a> <ExternalLink className="inline h-4 w-4" /></li>
            <li>For the Internet Explorer web browser, please visit this page from Microsoft: <a href="http://support.microsoft.com/kb/278835" target="_blank" rel="noopener noreferrer" className="text-indigo-400 hover:text-indigo-300">http://support.microsoft.com/kb/278835</a> <ExternalLink className="inline h-4 w-4" /></li>
            <li>For the Firefox web browser, please visit this page from Mozilla: <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" target="_blank" rel="noopener noreferrer" className="text-indigo-400 hover:text-indigo-300">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a> <ExternalLink className="inline h-4 w-4" /></li>
            <li>For the Safari web browser, please visit this page from Apple: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank" rel="noopener noreferrer" className="text-indigo-400 hover:text-indigo-300">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a> <ExternalLink className="inline h-4 w-4" /></li>
          </ul>
          <p className="mb-4">
            For any other web browser, please visit your web browser's official web pages.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">More Information</h2>
          <p className="mb-4">
            Hopefully, this policy has clarified things for you. As was previously mentioned, if there is something that you aren't sure whether you need or not, it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.
          </p>
          <p className="mb-4">
            However, if you are still looking for more information, you can contact us through one of our preferred contact methods:
          </p>
          <p className="mb-2">Email: info@verilo.com</p>
          {/* <p className="mb-2">Address: 123 AI Avenue, Tech City, TC 12345, United States</p>
          <p>Phone: +1 (555) 123-4567</p> */}
        </section>

        <p className="text-sm text-gray-400 text-center">
          Last Updated: August 22, 2024
        </p>
      </main>

    </div>
  );
};

export default CookiePolicy;