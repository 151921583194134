import React from 'react';
import { ArrowRight, Check, Shield, Zap, TrendingUp, FileText } from 'lucide-react';

const EventLandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <header className="bg-gray-800 bg-opacity-90 backdrop-filter backdrop-blur-lg py-3">
        <div className="max-w-6xl mx-auto px-4 flex justify-between items-center">
          <div className="flex items-center">
            <img 
              src="veriloLogo512.png" 
              alt="Verilo Logo" 
              className="w-8 h-8 mr-2"
            />
            <span className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">
              Verilo
            </span>
          </div>
          <a 
            href="/#/event/form"
            target="_blank"
            rel="noopener noreferrer" 
            className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 transition text-sm font-semibold"
          >
            Apply For Early Access
          </a>
        </div>
      </header>

      <main className="max-w-6xl mx-auto px-4">
        <div className="py-12 grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div>
            <div className="bg-indigo-500 text-white text-sm font-semibold px-3 py-1 rounded-full inline-block mb-4">
              Beta Access Available
            </div>
            <h1 className="text-4xl font-bold mb-4 leading-tight text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">
              Make Better Hires. Save 3x.
            </h1>
            <p className="text-xl text-gray-300 mb-6">
              Our AI analyzes interview recordings to detect confidence and competency signals that humans miss. Get objective insights that are 70% more accurate than gut feelings.
            </p>
            
            {/* Early Access Banner */}
            <div className="flex items-center space-x-4 mb-6">
              <div className="bg-indigo-500/10 px-4 py-2 rounded-lg flex items-center">
                <div className="h-2 w-2 bg-indigo-400 rounded-full animate-pulse mr-2"></div>
                <p className="text-sm text-gray-300">
                  <span className="text-indigo-400 font-semibold">Limited spots available</span> for beta access
                </p>
              </div>
            </div>

            {/* Primary CTA with Urgency */}
            <div className="bg-gray-800 p-6 rounded-lg mb-6">
              <div className="flex flex-col sm:flex-row items-center justify-between">
                <div className="mb-4 sm:mb-0">
                  <h3 className="text-lg font-semibold mb-1">Limited Time Beta Offer</h3>
                  <p className="text-gray-300">Apply for 3 months free - Ends Soon</p>
                </div>
                <a 
                  href="/#/event/form"
                  target="_blank"
                  rel="noopener noreferrer" 
                  className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white px-6 py-3 rounded-lg hover:from-indigo-600 hover:to-purple-700 transition font-semibold flex items-center whitespace-nowrap"
                >
                  Apply For Your Free Trial
                  <ArrowRight className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>
          </div>

          {/* Video Section */}
          <div className="relative">
            <div className="aspect-video rounded-lg overflow-hidden shadow-2xl">
              <video
                autoPlay
                muted
                loop
                playsInline
                controls
                className="w-full h-full object-cover"
                poster="/path/to/your/video-thumbnail.jpg"
                defaultMuted
              >
                <source src="videos/verilo_video.mp4" type="video/mp4" />
              </video>
            </div>
            
            {/* Results Overlay */}
            <div className="absolute top-4 right-4 bg-gray-800/90 backdrop-blur-sm p-4 rounded-lg shadow-lg">
              <div className="text-sm font-semibold text-gray-300">Accuracy Rate</div>
              <div className="text-2xl font-bold text-indigo-400">70%</div>
              <div className="text-xs text-gray-400">vs 49% human average</div>
            </div>
          </div>
        </div>

        {/* How It Works - NEW SECTION */}
        <div className="py-8">
          <h2 className="text-2xl font-bold mb-6 text-center">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-gray-800 p-6 rounded-lg">
              <div className="flex items-center mb-4">
                <div className="bg-indigo-500/10 p-2 rounded-lg mr-3">
                  <FileText className="h-5 w-5 text-indigo-400" />
                </div>
                <span className="text-lg font-semibold">1. Upload Interviews</span>
              </div>
              <p className="text-sm text-gray-300">Submit your recorded interviews - works with any format, online or in-person</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-lg">
              <div className="flex items-center mb-4">
                <div className="bg-indigo-500/10 p-2 rounded-lg mr-3">
                  <Zap className="h-5 w-5 text-indigo-400" />
                </div>
                <span className="text-lg font-semibold">2. AI Analysis</span>
              </div>
              <p className="text-sm text-gray-300">Our AI detects confidence signals in speech patterns and word choice</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-lg">
              <div className="flex items-center mb-4">
                <div className="bg-indigo-500/10 p-2 rounded-lg mr-3">
                  <TrendingUp className="h-5 w-5 text-indigo-400" />
                </div>
                <span className="text-lg font-semibold">3. Get Insights</span>
              </div>
              <p className="text-sm text-gray-300">Receive detailed confidence analysis and red flags for informed decisions</p>
            </div>
          </div>
        </div>

        {/* Key Metrics - Now visually distinct */}
        <div className="py-12 bg-gray-800/50 rounded-xl">
          <h2 className="text-2xl font-bold mb-8 text-center">Proven Results</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto px-4">
            <div className="text-center">
              <div className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-2">21%</div>
              <p className="text-sm text-gray-300">More accurate than<br />human judgment</p>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-2">3-4x</div>
              <p className="text-sm text-gray-300">Potential salary savings<br />per bad hire prevented</p>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-2">24hr</div>
              <p className="text-sm text-gray-300">Average time to<br />detailed analysis</p>
            </div>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="py-12">
          <div className="bg-gradient-to-r from-indigo-500/20 to-purple-600/20 rounded-lg p-8 text-center">
            <h2 className="text-2xl font-bold mb-4">Ready to Transform Your Hiring?</h2>
            <p className="text-gray-300 mb-6 max-w-2xl mx-auto">
              Get objective insights into every interview. Start your 3-month free trial today.
            </p>
            <a 
              href="/#/event/form"
              target="_blank"
              rel="noopener noreferrer" 
              className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white px-8 py-3 rounded-lg hover:from-indigo-600 hover:to-purple-700 transition font-semibold inline-flex items-center"
            >
              Apply For Free Access
              <ArrowRight className="ml-2 h-5 w-5" />
            </a>
          </div>
        </div>
      </main>

      {/* Minimal Footer */}
      <footer className="bg-gray-800 py-4 mt-8">
        <div className="max-w-6xl mx-auto px-4 text-sm text-gray-400 flex justify-between items-center">
          <p>© 2024 Verilo. All rights reserved.</p>
          <div className="flex items-center">
            <Shield className="h-4 w-4 mr-1" />
            <span>256-bit Encryption</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default EventLandingPage;